<template>
  <div class="page-container">
    <v-container grid-list-md>
      <v-layout wrap>
        <!-- {{$route.params.id}} -->

        <v-flex v-for="n in Active" :key="n">
          <v-item>
            <v-hover open-delay="200">
              <v-card
                class="d-flex align-center card-custom"
                color="primary"
                dark
                @click="give_label(n)"
              >
                <v-card-title>
                  <v-list-item class="grow">
                    <v-card-text class="text-center font-weight-bold">
                      <span class="headline font-weight-bold">{{ n }}</span>
                    </v-card-text>
                  </v-list-item>
                </v-card-title>
              </v-card>
            </v-hover>
          </v-item>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
// import * as Buffer from "vuetify";

const axios = require("axios");
import store from "../store";

export default {
  data: () => ({
    cors: "https://cors-anywhere.herokuapp.com/",
    sperm_image: null,
    // username: "",
    userid: "",
    Active: ["EmbryoStage", "ICM", "Trophectoderm"],
    label: "",
    imageno: "",
    // slide: "",
    image_name: "",
    current_slide: ""
  }),
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    get_user() {
      return store.state.userid;
    }
  },
  watch: {},

  created() {
    // this.$parent.subtitle = "modules";
    // console.log("Created");
    // this.give_modules();
    this.userid = store.state.userid;

    // console.log("userid: ", this.userid);
    if (this.userid == null) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    give_modules: function() {
      const get_sli = this.$backendhostname + "/getmodules";
      axios
        // .post(this.cors + get_sli, {
        .post(get_sli, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })

        .then(response => {
          // console.log(response);
          this.Active = response.data.modules.split(";");
          // var res = response.data.user

          // dispatch({type: FOUND_USER, data: response.data[0]})
        })
        .catch(error => {
          this.resp = error;
          console.error(error);

          // dispatch({type: ERROR_FINDING_USER})
        });
    },
    give_label: function(temp) {
      this.current_slide = temp;
      store.commit("store_current_slide", this.current_slide);

      this.$router.push({
        // path: `/slide/${this.current_slide}/${this.username}`

        path: "/grade"
      });
    }
  }
};
</script>

<style>
.page-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Ensure the container covers the full viewport height */
}
.card-custom {
  align-content: center;

  /*height: 100px;*/
}
/*@media (max-width: 425px) {*/
/*  .temp {*/
/*    flex-direction: row;*/
/*  }*/
/*  .card-custom {*/
/*    !*height: 100px;*!*/
/*    align-content: center;*/
/*    !*width: 200px;*!*/
/*  }*/

/*  .image {*/
/*    !* object-fit: cover; *!*/
/*    !* max-height: 60vh; *!*/
/*    !* height: 30px; *!*/
/*    !* width: 30px; *!*/
/*    !* width: 100v÷w; *!*/
/*  }*/
/*}*/
.image {
  /* object-fit: cover; */
  /* max-height: 60vh; */
  /* height: 80vh; */
  /* width: 80vh; */
  /* width: 100v÷w; */
}
</style>
<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
