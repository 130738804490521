<template>
  <div class="page-container-grade">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <!--          <h4>{{ image_number }} / {{ number_of_images }}</h4>-->
          <table style="border: 1px solid black; width: 100%;">
            <tr>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  Module
                </span>
              </td>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  EmbryoStage
                </span>
              </td>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass"> ICM </span>
              </td>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  Trophectoderm
                </span>
              </td>
            </tr>
            <tr>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  SetNo, ImageNo
                </span>
              </td>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  {{ EmbryoStageSetNo }},
                  {{ computeLocalImageNumber(EmbryoStageImageCount) }}/{{
                    ImageInSetCount
                  }}
                </span>
              </td>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  {{ ICMGradeSetNo }},
                  {{ computeLocalImageNumber(ICMGradeImageCount) }}/{{
                    ImageInSetCount
                  }}
                </span>
              </td>
              <td style="border: 1px solid black;">
                <span :class="textHeadClass">
                  {{ TrophectodermSetNo }},
                  {{ computeLocalImageNumber(TrophectodermImageCount) }}/{{
                    ImageInSetCount
                  }}
                </span>
              </td>
            </tr>
          </table>
        </v-col>

        <v-col cols="12" md="12" class="text-center">
          <span :class="textHeadClass">
            Current Set No: {{ computeSetNo(image_number) }}, Image:
            {{ computeLocalImageNumber(image_number) }}/{{ ImageInSetCount }},
            Sets Completed: {{ totalSetCompleted }} Embryos Annotated: =
            {{ totalAnnotated }}
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <!-- Display the image -->
          <div class="image-container">
            <v-btn color="primary" icon x-large @click="getPrevious()">
              <v-icon left>
                mdi-arrow-left
              </v-icon>
            </v-btn>
            <!-- Display loading spinner or placeholder -->
            <!--            <div v-if="imageLoading" class="loading-spinner">-->
            <!--              <v-progress-circular-->
            <!--                indeterminate-->
            <!--                color="primary"-->
            <!--              ></v-progress-circular>-->
            <!--            </div>-->

            <img
              v-if="imageLoading"
              class="image-rotate"
              src="@/assets/placeholder.gif"
              alt="Completed Slide"
            />
            <img
              v-if="everything_completed"
              class="image-rotate"
              src="@/assets/completed_mark.png"
              alt="Completed Slide"
            />
            <!-- Display image when not loading -->
            <img
              v-else-if="not_completed && !imageLoading"
              class="image-rotate"
              :src="raw_image"
              alt="Image"
              :style="{ transform: `rotate(${rotation}deg)` }"
            />
            <v-btn color="primary" icon x-large v-if="label" @click="getNext()">
              <v-icon right>
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" md="6" sm="12" xs="12">
          <!-- Side Panel for Selection -->
          <v-card class="side-panel">
            <v-row>
              <v-col cols="6" md="6" sm="6" xs="6">
                <!-- EmbryoStage Selection -->
                <div :class="textHeadClass">
                  <span v-if="EmbryoStageModule"> Embryo Stage </span>
                  <span v-if="ICMModule"> ICM Grade </span>
                  <span v-if="TrophectodermModule"> Trophectoderm Grade </span>
                </div>
              </v-col>
              <v-col cols="6">
                <v-card-actions class="justify-center">
                  <v-btn
                    :color="label === '-1' ? 'primary' : 'error'"
                    @click="flag"
                  >
                    <v-icon left>mdi-flag</v-icon>
                    Flag
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>

            <v-item-group v-if="EmbryoStageModule" selected-class="bg-primary">
              <v-container>
                <v-row>
                  <v-col
                    v-for="n in EmbryoStageOptions"
                    :key="n"
                    cols="4"
                    md="4"
                    xs="4"
                    sm="4"
                    lg="4"
                  >
                    <v-item>
                      <v-card
                        :class="[
                          'd-flex align-center',
                          { primary: cardClicked === n }
                        ]"
                        dark
                        @click="submit(n)"
                        @touchstart="startTouch(n)"
                        @touchend="endTouch"
                      >
                        <div :class="textClass" class="flex-grow-1 text-center">
                          {{ n }}
                        </div>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>

            <v-divider v-if="EmbryoStageModule"></v-divider>

            <!-- ICM Grade Selection -->
            <v-item-group v-if="ICMModule" selected-class="bg-primary">
              <v-container>
                <v-row>
                  <v-col
                    v-for="n in ICMGradeOptions"
                    :key="n"
                    cols="6"
                    md="6"
                    xs="6"
                    sm="6"
                    lg="6"
                  >
                    <v-item>
                      <v-card
                        :class="[
                          'd-flex align-center',
                          { primary: cardClicked === n }
                        ]"
                        dark
                        @click="submit(n)"
                        @touchstart="startTouch(n)"
                        @touchend="endTouch"
                      >
                        <div :class="textClass" class="flex-grow-1 text-center">
                          {{ n }}
                        </div>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>

            <!-- Trophectoderm Grade Selection -->

            <v-item-group
              v-if="TrophectodermModule"
              selected-class="bg-primary"
            >
              <v-container>
                <v-row>
                  <v-col
                    v-for="n in TrophectodermGradeOptions"
                    :key="n"
                    cols="6"
                    md="6"
                    xs="6"
                    sm="6"
                    lg="6"
                  >
                    <v-item>
                      <v-card
                        :class="[
                          'd-flex align-center',
                          { primary: cardClicked === n }
                        ]"
                        dark
                        @click="submit(n)"
                        @touchstart="startTouch(n)"
                        @touchend="endTouch"
                      >
                        <div :class="textClass" class="flex-grow-1 text-center">
                          {{ n }}
                        </div>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>

            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" persistent class="page-container-grade">
        <v-card>
          <v-card-title class="headline">Annotation Status</v-card-title>
          <v-row>
            <v-col cols="12" class="text-center">
              {{ statusMessage }}
            </v-col>
            <v-col cols="12" class="text-center">
              <!--          <h4>{{ image_number }} / {{ number_of_images }}</h4>-->
              Sets Completed: {{ totalSetCompleted }}

              Embryos Annotated: = {{ totalAnnotated }}
              <table style="border: 1px solid black; width: 100%;">
                <tr>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      Module
                    </span>
                  </td>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      EmbryoStage
                    </span>
                  </td>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass"> ICM </span>
                  </td>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      Trophectoderm
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      SetNo, ImageNo
                    </span>
                  </td>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      {{ EmbryoStageSetNo }},
                      {{ computeLocalImageNumber(EmbryoStageImageCount) }}/{{
                        ImageInSetCount
                      }}
                    </span>
                  </td>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      {{ ICMGradeSetNo }},
                      {{ computeLocalImageNumber(ICMGradeImageCount) }}/{{
                        ImageInSetCount
                      }}
                    </span>
                  </td>
                  <td style="border: 1px solid black;">
                    <span :class="textHeadClass">
                      {{ TrophectodermSetNo }},
                      {{ computeLocalImageNumber(TrophectodermImageCount) }}/{{
                        ImageInSetCount
                      }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
// import * as Buffer from "vuetify";

import store from "@/store";

const axios = require("axios");

export default {
  data: () => ({
    ICMGradeOptions: ["A", "B", "C", "D", "noICM"],

    TrophectodermGradeOptions: ["A", "B", "C", "D", "noTroph"],
    EmbryoStageOptions: ["1", "2", "3", "4", "5", "6", "M-A", "M-B", "A/D"],

    // Color variables for each option with "dark" as the default color
    ICMGradeColors: {
      A: "dark",
      B: "dark",
      C: "dark",
      D: "dark",
      noICM: "dark"
    },
    TrophectodermGradeColors: {
      A: "dark",
      B: "dark",
      C: "dark",
      D: "dark",
      noTroph: "dark"
    },
    EmbryoStageColors: {
      "1": "dark",
      "2": "dark",
      "3": "dark",
      "4": "dark",
      "5": "dark",
      "6": "dark",
      "M-A": "dark",
      "M-B": "dark",
      "A/D": "dark"
    },
    flagColor: "error",
    selectedICMGrade: null,
    selectedTrophectodermGrade: null,
    selectedEmbryoStage: null,
    loader: null,
    loading: false,

    submit_button_disable: true,

    cors: "https://cors-anywhere.herokuapp.com/",
    raw_image: null,

    label: null,
    imageno: "",
    // slide: "",
    image_name: "",
    image_number: "",
    number_of_images: "",
    user_id: "",
    everything_completed: false,
    not_completed: false,
    rotation: 0,
    flag_val: true,
    imageLoading: false,
    EmbryoStageModule: false,
    ICMModule: false,
    TrophectodermModule: false,
    cardClicked: null,
    cardClicked2: null,
    enableRightArrow: false,
    annotation_status: null,
    EmbryoStageImageCount: 0,
    ICMGradeImageCount: 0,
    TrophectodermImageCount: 0,

    EmbryoStageSetNo: null,
    ICMGradeSetNo: null,
    TrophectodermSetNo: null,
    ImageInSetCount: 500,

    dialog: false,
    statusMessage: ""
  }),

  watch: {
    image_name: function() {
      if (this.image_name) this.get_image();
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 1000);

      this.loader = null;
    },

    // Watch for changes in each SetNo
    EmbryoStageSetNo(newVal, oldVal) {
      this.checkSetNo("EmbryoStage", newVal, oldVal);
    },
    ICMGradeSetNo(newVal, oldVal) {
      this.checkSetNo("ICM", newVal, oldVal);
    },
    TrophectodermSetNo(newVal, oldVal) {
      this.checkSetNo("Trophectoderm", newVal, oldVal);
    }
  },
  computed: {
    textClass() {
      // Define classes for different screen sizes
      return {
        "text-h4": this.$vuetify.breakpoint.smAndUp, // Small screens and up
        "text-h6": this.$vuetify.breakpoint.xsOnly // Extra small screens only
        // You can add more classes for other breakpoints as needed
      };
    },
    textHeadClass() {
      // Define classes for different screen sizes
      return {
        "text-h5": this.$vuetify.breakpoint.smAndUp, // Small screens and up
        "text-h6": this.$vuetify.breakpoint.xsOnly // Extra small screens only
        // You can add more classes for other breakpoints as needed
      };
    },

    computeSetNo() {
      return image_number => {
        return Math.floor(image_number / this.ImageInSetCount) + 1;
      };
    },
    computeLocalImageNumber() {
      return image_number => {
        return (image_number % this.ImageInSetCount) + 1;
      };
    },

    totalAnnotated() {
      return Math.min(
        this.EmbryoStageImageCount,
        this.ICMGradeImageCount,
        this.TrophectodermImageCount
      );
    },
    totalSetCompleted() {
      return Math.min(
        this.EmbryoStageSetNo,
        this.ICMGradeSetNo,
        this.TrophectodermSetNo
      );
    },

    // computeSetNo() {
    //   return Math.round(this.image_number / this.ImageInSetCount) + 1;
    // },
    // computeLocalImageNumber() {
    //   return this.image_number % this.ImageInSetCount;
    // },

    get_user() {
      return store.state.userid;
    },

    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    }
  },

  created() {
    this.clearForm();
    this.userid = store.state.userid;
    this.slide_id = store.state.current_slide;

    this.setModules();

    // console.log("userid: ", this.userid);
    if (this.userid == null) {
      this.$router.push({ path: "/login" });
    }

    this.username = store.state.username;
    this.getimagenumber();

    // console.log("Username: ", this.username);
    // console.log("userid: ", this.userid);
    // console.log("image_name: ", this.image_name);
    // console.log("slide_id: ", this.slide_id);

    // console.log("size: ", this.$vuetify.breakpoint.name);

    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.rotation -= 0;
    }
    // this.submit_button_disable = this.disableSubmit();

    // this.reset_ann()
  },
  rotateRight() {},
  methods: {
    checkSetNo(moduleName, currentSetNo, oldSetNo) {
      // Get the SetNo for the other modules

      if (oldSetNo === null) return;

      this.dialog = false;
      // show staus meesga saying moduleName oldSetNo is completed
      this.statusMessage = `${moduleName} Set${oldSetNo} is completed please complete other modules first until Set${oldSetNo}`;
      this.dialog = true;
    },
    showPopup(message) {
      // Implement the logic to show popup here
      alert(message); // For simplicity, using alert. Replace with your popup logic.
    },

    getButtonColor() {
      if (this.label === -1) {
        return "primary"; // Set the button color to primary when label is -1
      } else {
        return "error"; // Set the button color to error for other cases
      }
    },
    // EmbryoStageClick: function(name) {
    //   this.selectedEmbryoStage = name;
    //   this.submit();
    // },

    startTouch(n) {
      // Add a class when touch starts
      this.cardClicked = n; // Change this to your desired color class
    },
    endTouch() {
      // Remove the class when touch ends
      this.cardClicked = null;
    },
    clearForm() {
      this.selectedICMGrade = null;
      this.selectedTrophectodermGrade = null;
      this.selectedEmbryoStage = null;
      this.submit_button_disable = true;
      this.flag_val = false;
      this.label = null;
    },

    setModules() {
      // Assuming this code is inside your Vue component
      this.slide_id = store.state.current_slide;

      if (this.slide_id === "EmbryoStage") {
        this.EmbryoStageModule = true;
        this.ICMModule = false;
        this.TrophectodermModule = false;
        // Add more module flags and set them accordingly
      } else if (this.slide_id === "ICM") {
        this.EmbryoStageModule = false;
        this.ICMModule = true;
        this.TrophectodermModule = false;
        // Add more module flags and set them accordingly
      } else if (this.slide_id === "Trophectoderm") {
        this.EmbryoStageModule = false;
        this.ICMModule = false;
        this.TrophectodermModule = true;
        // Add more module flags and set them accordingly
      } else {
        // Handle other cases if needed
        this.EmbryoStageModule = false;
        this.ICMModule = false;
        this.TrophectodermModule = false;
        // Add more module flags and set them accordingly
      }
    },

    flag: function() {
      this.flag_val = true;
      this.submit("-1");
    },
    submit: function(label) {
      this.loader = "loading";
      this.loading = !this.submit_button_disable;

      // if (this.EmbryoStageModule) {
      //   label = this.selectedEmbryoStage;
      // } else if (this.ICMModule) {
      //   label = this.selectedICMGrade;
      // } else if (this.TrophectodermModule) {
      //   label = this.selectedTrophectodermGrade;
      // }
      let comment = "";
      if (this.flag_val) {
        label = "-1";
        comment = "unable to annotate";
      }
      console.log(label);

      let data = {
        username: this.userid,
        modulename: this.slide_id,
        image_name: this.image_name,
        label: label,
        comment: comment
      };

      const get_label = this.$backendhostname + "/give_label";

      axios
        .post(get_label, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })

        .then(response => {
          if (response.data.response) {
            // console.log(response.data.response);
            this.raw_image = "";
            this.getimagenumber();
            this.clearForm();
          }

          // var res = response.data.user

          // dispatch({type: FOUND_USER, data: response.data[0]})
        })
        .catch(error => {
          this.resp = error;
          console.error(error);
          this.clearForm();

          // dispatch({type: ERROR_FINDING_USER})
        });
    },

    getPrevious: function() {
      let data = {
        username: this.userid,
        modulename: this.slide_id,
        image_name: this.image_name
      };

      const get_label = this.$backendhostname + "/get_previous";

      axios
        .post(get_label, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })

        .then(response => {
          if (response.data.response === "success") {
            // console.log(response.data.response);
            this.label = response.data.label;
            this.cardClicked = this.label;

            if (this.label === "-1") {
              this.flagColor = "primary";
            }

            this.image_name = response.data.image_name;
            this.image_number = response.data.image_number;
            this.number_of_images = response.data.number_of_images;
          }
        })
        .catch(error => {
          this.resp = error;
          console.error(error);
          this.clearForm();

          // dispatch({type: ERROR_FINDING_USER})
        });
    },
    getNext: function() {
      let data = {
        username: this.userid,
        modulename: this.slide_id,
        image_name: this.image_name
      };

      const get_label = this.$backendhostname + "/get_next";

      axios
        .post(get_label, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })

        .then(response => {
          if (response.data.response === "success") {
            // console.log(response.data.response);
            this.label = response.data.label;
            this.cardClicked = this.label;

            if (this.label === "-1") {
              this.flagColor = "primary";
            }

            this.image_name = response.data.image_name;
            this.image_number = response.data.image_number;
            this.number_of_images = response.data.number_of_images;
          }
        })
        .catch(error => {
          this.resp = error;
          console.error(error);
          this.clearForm();

          // dispatch({type: ERROR_FINDING_USER})
        });
    },

    getimagenumber: function() {
      // console.log(this.user_id);
      this.imageLoading = true;

      let data = {
        username: this.userid,
        modulename: this.slide_id
      };
      // console.log(data);

      const get_image_number_url = this.$backendhostname + "/get_image_number";

      // this.$route.params

      axios
        .post(get_image_number_url, data, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })

        .then(response => {
          if (response.data.response === "success") {
            // console.log("success");
            this.not_completed = true;
            this.batch_completed = false;
            this.everything_completed = false;

            this.image_name = response.data.image_name;
            this.image_number = response.data.image_number;
            this.number_of_images = response.data.number_of_images;

            this.imageLoading = false;
            this.annotation_status = response.data.annotation_status;
            // console.log("annotation_status");
            // console.log(this.annotation_status);
            if (this.annotation_status) {
              for (let module in this.annotation_status) {
                let count = this.annotation_status[module];

                if (module === "EmbryoStage") {
                  this.EmbryoStageImageCount = count;
                  this.EmbryoStageSetNo = this.computeSetNo(count);
                } else if (module === "ICM") {
                  this.ICMGradeImageCount = count;
                  this.ICMGradeSetNo = this.computeSetNo(count);
                } else if (module === "Trophectoderm") {
                  this.TrophectodermImageCount = count;
                  this.TrophectodermSetNo = this.computeSetNo(count);
                }
              }
            }

            return response.data.image_name;
          } else if (response.data.response === "completed") {
            this.batch_completed = false;
            this.not_completed = false;
            this.everything_completed = true;
          }

          // var res = response.data.user

          // dispatch({type: FOUND_USER, data: response.data[0]})
        })
        .catch(error => {
          this.resp = error;
          console.error(error);
          this.imageLoading = false;

          // dispatch({type: ERROR_FINDING_USER})
        });
    },

    get_image: function() {
      this.imageLoading = true; // Set loading to true
      let data = {
        image_name: this.image_name
      };

      const get_image_url = this.$backendhostname + "/get_image";

      axios
        .post(get_image_url, data, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept"
          }
        })

        .then(response => {
          this.raw_image = this._imageEncode(response.data);
          this.imageLoading = false; // Set loading to false when image is loaded
        })
        .catch(error => {
          this.resp = error;
          console.error(error);
          this.imageLoading = false; // Set loading to false in case of an error
        });
    },

    _imageEncode: function(arrayBuffer) {
      // let u8 = new Uint8Array(arrayBuffer);
      let b64encoded = btoa(
        [].reduce.call(
          new Uint8Array(arrayBuffer),
          function(p, c) {
            return p + String.fromCharCode(c);
          },
          ""
        )
      );
      let mimetype = "image/png";
      return "data:" + mimetype + ";base64," + b64encoded;
    }
  }
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
  margin: auto;
  border: 1px solid #ccc;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.selected-chip {
  color: white; /* Customize the selected chip text color */
}

.chip-size {
  height: 80px; /* You can adjust the height value */
  font-size: 20px; /* You can adjust the font size */
  padding: 20px 16px; /* You can adjust the padding */
}

.chip-group-size {
  margin: 0 20px 0px 20px; /* You can adjust the margin value */
  padding: 20px 16px; /* You can adjust the padding */
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image-completed {
  width: 100%;
  max-height: 400px;
}

/* Default styles for .image-rotate */
.image-rotate {
  max-width: 95%;
  max-height: 95%;
  transition: transform 0.3s ease;
}

/* Media query for screens less than tablet size */
@media (max-width: 425px) {
  .image-rotate {
    max-height: 30vh;
  }
}

.side-panel {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100vw;
}

.chip-group {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.justify-center {
  justify-content: center;
}

.page-container-grade {
  display: flex;
  justify-content: center; /* Center horizontally */
  max-height: 100vh; /* Ensure the container covers the full viewport height */
}
</style>
